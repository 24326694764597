
import { defineComponent } from 'vue';
import _ from 'lodash';
// import { CheckIcon, XIcon, TrashIcon, PencilAltIcon} from '@heroicons/vue/outline';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import Multiselect from '@vueform/multiselect';
import { emitter, toast } from '../main';
import useVuelidate from '@vuelidate/core';
import { required, helpers, email } from '@vuelidate/validators';

export default defineComponent({
	mixins: [globalApiMixin],
	// components: { CheckIcon, XIcon, TrashIcon, PencilAltIcon, PlusSmIcon},
	data(): any {
		return {
			v$: useVuelidate(),
			visibility: false,
			dummyList: [],
			teamsList: [],
			currentSort: 'name',
			currentSortDir: 'asc',
			classSort: '',
			className: '',
			optionsUserArray: [],
			pageSize: 12,
			currentPage: 1,
			filterDummy: {} 
		};
	},
	components: { Multiselect },
	validations() {
		return {
			teamsList: {
				$each: helpers.forEach({
					teamName: { required },
					teamOwner: { required }
				})
			}
		};
	},
	watch: {
		filterDummy: {
			handler(newVal: any) {
				this.filterDummy = newVal;
				this.filteredFunction();
			},
			deep: true
		}
	},
	methods: {
		filterOpen(){
			this.visibility = !this.visibility;
			this.filterDummy = {};
		},
		filteredFunction() {
				let filteredPairs: any = {};
			for (const key in this.filterDummy) {
				const value = this.filterDummy[key];
				if (value !== '') {
					filteredPairs[key] = value;
				}
			}
			let propertyArray = Object.keys(filteredPairs);
			this.teamsList = this.dummyList.filter((item: any) => {
				for (let key of propertyArray) {
					console.log('outer for 1');
					let filterColumn = this.filterDummy[key];
					let columnName = key;
					let hasMatch = false;
				 for (var property in item) {
					if (property == columnName) {
						if(columnName=='teamOwner'){
							let a=this.extractDepartmentName(item.teamOwner);
							if( a.toLowerCase().includes(filterColumn.toLowerCase())){
								hasMatch = true;
							}
						}
						else{
							if(item[property].toLowerCase().includes(filterColumn.toLowerCase()))
							hasMatch = true;
						}
						
					}
					if (hasMatch) {break};
				 }
				 if (!hasMatch) return false;
				}
				return true;
			});
		},
		extractDepartmentName(name:any) {
			let userName = this.users.find((user:any)=>{
				return user._id == name
			})
			if (userName) {
				return userName.name
			}
			else {
				return ''
			}
			
		},
		nextPage: function () {
			if (this.currentPage * this.pageSize < this.teamsList.length) this.currentPage++;
		},
		prevPage: function () {
			if (this.currentPage > 1) this.currentPage--;
		},
		addTeam(): any {
			this.teamsList.push({
				teamName: '',
				teamOwner: '',
				toggleInput: true,
				showSave: true
			});
			this.addAction = true;
			this.editAction = false;
		},

		editTeam(d: any) {
			d.toggleInput = true;
			d.showSave = true;
			this.addAction = false;
			this.editAction = true;
		},
		clearAllFlags(d: any, teamindex: any) {
			d.toggleInput = false;
			d.showSave = false;
			if (this.addAction) {
				this.teamsList.splice(teamindex, 1);
			}
		},
		saveTeam(teamindex: any) {
			this.v$.$touch();
			let finalTeams = this.teamsList.slice(0, teamindex);
			let teamExists = finalTeams.some((el: any) => {
				return el.teamName.toLowerCase() === this.teamsList[teamindex].teamName.toLowerCase();
			});
			let errorCondition = this.v$.teamsList.$each.$message[teamindex];
			if (!teamExists && errorCondition.length == 0) {
				this.v$.$reset();
				if (this.teamsList[teamindex]._id) {
					this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/departments/update/${this.teamsList[teamindex]._id}`, _.omit(this.teamsList[teamindex], ['toggleInput', 'showSave', '_id'])).then(() => {
						this.getAllTeams();
						toast.info(`Team Updated`, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
						});
			
					});
				} else {
					this.$http.post(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/departments/create`, [_.omit(this.teamsList[teamindex], ['toggleInput', 'showSave'])]).then(() => {
						this.getAllTeams();
							toast.info(`Team Saved`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
							});
					});
				}
			} 
		},
		deleteTeam(teamindex: any, team: any) {
			// if(team._id){
			this.$http.delete(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/departments/delete/${this.teamsList[teamindex]._id}`).then(() => {
				this.getAllTeams();
					toast.error(`Team Deleted`, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
			});
			// }
			// else{
			// 	team.splice(teamindex, 1)
			// }
		},
			async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/users/getAll`).then((res: any) => {
				this.users = res.data;
			});
			for (var i = 0; i < this.users.length; i++) {
				this.optionsUserArray.push({label:this.users[i].name,value:this.users[i]._id});
			}
		},
		async getAllTeams() {
			await this.$http.get(`${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_AUTH_ORG_PATH}/departments/get`).then((res: any) => {
				console.log('departments list', res.data);
				this.teamsList = res.data;
				this.dummyList = res.data;
			});
		},
		sort: function (s: any) {
			console.log("ss")
			this.className = s;
			if (s === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = s;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
	},
	computed: {
		sortedData: function () {
			return [...this.teamsList]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		cantGoBack() {
			return this.currentPage === 1;
		},
		cantGoForward() {
			return this.teamsList.length / this.pageSize <= this.currentPage;
		}
	},
	async mounted() {
		await this.getAllUsers();
		await this.getAllTeams();
		
	}
});
